import React from "react";
import MatchesAndSelectionRow from "./MatchesAndSelectionRow";
import { useFitFinder } from "../../hooks/useFitFinder";
export default function FitFinder() {
  const sessionIds = [
    "b7d4a32b1cc62e5f3b19e2d07584cee259d8544f69a164e7935728548c301c10",
    "b7d4a32b1cc62e5f3b19e2d07584cee259d8544f69a165e49354285388381a16",
    "b7d4a32b1cc62e5f3b19e2d07584cee259d8544f69a161e290572a578d371d1f",
    // "b7d4a32b1cc62e5f3b19e2d07584cee259d8544f69a164e7935728548c301c10",
  ];
  const numSelectionsToDisplay = 10;
  const numMatchesToDisplay = 3;

  return (
    <div className="flex flex-col gap-4  px-4">
      <div className="flex flex-col gap-2">
        <h1 className="text-2xl font-bold">Fit Finder</h1>
        <p className="italic">Fit finder results</p>
        {sessionIds.map((sessionId) => (
          <FitFinderRow
            key={sessionId}
            sessionId={sessionId}
            numSelectionsToDisplay={numSelectionsToDisplay}
            numMatchesToDisplay={numMatchesToDisplay}
          />
        ))}
      </div>
    </div>
  );
}

function FitFinderRow({
  sessionId,
  numSelectionsToDisplay,
  numMatchesToDisplay,
}: {
  sessionId: string;
  numSelectionsToDisplay: number;
  numMatchesToDisplay: number;
}) {
  const { selections, matches, isLoading, error } = useFitFinder(sessionId);

  if (isLoading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <MatchesAndSelectionRow
      matches={matches.slice(0, numMatchesToDisplay)}
      selections={selections.slice(0, numSelectionsToDisplay)}
      sessionId={sessionId}
    />
  );
}
