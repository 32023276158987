import { useState, useCallback } from "react";
import {
  getProspectDetailsService,
  updateProspectDetailsService,
} from "../services/prospectService";
import { useAuth0 } from "@auth0/auth0-react";

export const useProspectDetails = () => {
  const [prospectInstagramHandle, setProspectInstagramHandle] = useState<
    string | null
  >(null);
  const [prospectLinkedinUrl, setProspectLinkedinUrl] = useState<string | null>(
    null
  );
  const [prospectEmail, setProspectEmail] = useState<string | null>(null);
  const [prospectPhoneNumber, setProspectPhoneNumber] = useState<string | null>(
    null
  );
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { getAccessTokenSilently } = useAuth0();

  const fetchProspectDetails = useCallback(async (prospectId: string) => {
    setLoading(true);
    setError(null);
    try {
      const accessToken = await getAccessTokenSilently();
      const details = await getProspectDetailsService(prospectId, accessToken);
      setProspectInstagramHandle(details.instagram_handle);
      setProspectLinkedinUrl(details.linkedin);
      setProspectEmail(details.email);
      setProspectPhoneNumber(details.phone_number);
    } catch (err) {
      setError("Failed to fetch prospect details");
    } finally {
      setLoading(false);
    }
  }, []);

  const updateProspectDetails = useCallback(
    async (prospectId: string, details: any) => {
      setLoading(true);
      setError(null);
      try {
        const accessToken = await getAccessTokenSilently();
        await updateProspectDetailsService(prospectId, details, accessToken);
      } catch (err) {
        setError("Failed to update prospect details");
      } finally {
        setLoading(false);
      }
    },
    []
  );

  return {
    prospectInstagramHandle,
    prospectLinkedinUrl,
    prospectEmail,
    prospectPhoneNumber,
    setProspectInstagramHandle,
    setProspectLinkedinUrl,
    setProspectEmail,
    setProspectPhoneNumber,
    fetchProspectDetails,
    updateProspectDetails,
    loading,
    error,
  };
};
