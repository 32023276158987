import React, { FC, useState, useEffect } from "react";
import useCRM from "../../hooks/useCRM";
import Modal from "../../components/Modal";
import { Link } from "react-router-dom";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import AppBar from "../../components/AppBar";
import Select from "react-select";
import constants from "../../constants";

const Crm: FC = () => {
  const { users, selectedUser, loading, error, searchUsers, fetchUserDetails } =
    useCRM();
  const [searchQuery, setSearchQuery] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [sortBy, setSortBy] = useState("created_at");
  const [filterByMustHave, setFilterByMustHave] = useState<string[]>([]);
  const [filterByMustNotHave, setFilterByMustNotHave] = useState<string[]>([]);

  const handleSearch = (e: React.FormEvent) => {
    e.preventDefault();
    searchUsers(searchQuery, sortBy, filterByMustHave, filterByMustNotHave);
  };

  const handleUserClick = async (userId: string) => {
    await fetchUserDetails(userId);
    setIsModalOpen(true);
  };

  useEffect(() => {
    searchUsers(searchQuery, sortBy, filterByMustHave, filterByMustNotHave);
  }, [searchQuery, sortBy, filterByMustHave, filterByMustNotHave, searchUsers]);

  return (
    <div className="flex flex-col gap-2">
      {/* <AppBar /> */}
      <div className="px-4">
        <h2 className="text-4xl font-bold">Curate - CRM</h2>
        <form onSubmit={handleSearch} className="mb-4">
          <input
            type="text"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Search users..."
            className="border p-2 rounded"
          />
          <button
            type="submit"
            className="ml-2 bg-blue-500 text-white p-2 rounded"
          >
            Search
          </button>
        </form>
        <div className="mb-4 flex flex-wrap gap-4">
          <div className="w-64">
            <label className="block mb-2">Sort by:</label>
            <Select
              options={[
                { value: "created_at", label: "Created At" },
                {
                  value: "most_recent_message_recieved_timestamp",
                  label: "Most Recent Message",
                },
                {
                  value: "last_requested_more_matches",
                  label: "Last Requested Matches",
                },
                {
                  value: "last_viewed_converting_step",
                  label: "Last Viewed Converting Step",
                },
              ]}
              value={{ value: sortBy, label: sortBy.replace(/_/g, " ") }}
              onChange={(option) => option && setSortBy(option.value)}
            />
          </div>
          <div className="w-64">
            <label className="block mb-2">Filter by (must have):</label>
            <Select
              isMulti
              options={
                constants.EVENTS_TO_FILTER_BY.map((event) => ({
                  value: event,
                  label: event,
                })) as { value: string; label: string }[]
              }
              value={filterByMustHave.map((event) => ({
                value: event,
                label: event,
              }))}
              onChange={(options) =>
                setFilterByMustHave(options.map((option) => option.value))
              }
            />
          </div>
          <div className="w-64">
            <label className="block mb-2">Filter by (must not have):</label>
            <Select
              isMulti
              options={
                constants.EVENTS_TO_FILTER_BY.map((event) => ({
                  value: event,
                  label: event,
                })) as { value: never; label: never }[]
              }
              value={filterByMustNotHave.map((event) => ({
                value: event,
                label: event,
              }))}
              onChange={(options) =>
                setFilterByMustNotHave(options.map((option) => option.value))
              }
            />
          </div>
        </div>
        {loading && <p>Loading...</p>}
        {error && <p className="text-red-500">{error}</p>}
        <div className="flex flex-col gap-2">
          <h3 className="text-2xl font-bold">Users ({users.length})</h3>
          <ul>
            {users.map((user) => (
              <li key={user.user_id} className="mb-2">
                <Link
                  to={`/crm/${user.user_id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div
                    className={`hover:underline border p-4 rounded w-[500px] cursor-pointer ${
                      user.events.includes("event_match_sent")
                        ? "bg-green-50"
                        : ""
                    }`}
                  >
                    <div className="flex flex-col  text-left">
                      <span className="text-xs text-gray-500">
                        {new Date(user.created_at).toLocaleDateString("en-US", {
                          weekday: "short",
                          month: "short",
                          day: "numeric",
                        })}
                      </span>
                      <span className="text-md">
                        {user.name} {user.last_name} ({user.age}
                        {user.gender == "male" ? "M" : "F"}) |{" "}
                        {user.signup_location}
                      </span>
                      <span className="text-xs text-gray-500">
                        {user.most_recent_event
                          ? user.most_recent_event.replace("event_", "")
                          : "No recent event"}
                      </span>
                      <span className="text-xs text-gray-500">
                        {user.most_recent_message_recieved_timestamp &&
                          `Last message: ${new Date(
                            user.most_recent_message_recieved_timestamp
                          ).toLocaleDateString("en-US", {
                            weekday: "short",
                            month: "short",
                            day: "numeric",
                          })}`}
                      </span>
                    </div>
                  </div>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default withAuthenticationRequired(Crm, {
  returnTo: "/",
  onRedirecting: () => <Crm />,
});

// export default Crm;
