import React, { FC } from "react";
import styled from "styled-components";

interface Selection {
  image1: {
    image_base64: string;
  };
  image2: {
    image_base64: string;
  };
  prefered_image: number;
}

interface MatchesAndSelectionRowProps {
  selections: Selection[];
  matches: string[];
  sessionId: string;
}

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0px;
  overflow-x: auto;
  padding: 1px;
`;

const ImageContainer = styled.div<{
  isPreferred?: boolean;
  isNonPreferred?: boolean;
}>`
  border: 2px solid
    ${(props) =>
      props.isPreferred
        ? "green"
        : props.isNonPreferred
        ? "red"
        : "transparent"};
  border-radius: 4px;
  padding: 5px;
  margin-bottom: 10px;
`;

const Image = styled.img`
  width: 150px;
  height: 150px;
  object-fit: cover;
`;

const SelectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 5px;
`;

const MatchesAndSelectionRow: FC<MatchesAndSelectionRowProps> = ({
  selections,
  matches,
  sessionId,
}) => {
  return (
    <div>
      <h3>Session ID: {sessionId}</h3>
      <h4>Selections:</h4>
      <RowContainer>
        {selections.map((selection, index) => (
          <SelectionContainer key={index}>
            {selection.prefered_image === 1 ? (
              <>
                <ImageContainer isPreferred>
                  <Image
                    src={`data:image/jpeg;base64,${selection.image1.image_base64}`}
                    alt={`Selection ${index + 1} Preferred Image`}
                  />
                </ImageContainer>
                <ImageContainer isNonPreferred>
                  <Image
                    src={`data:image/jpeg;base64,${selection.image2.image_base64}`}
                    alt={`Selection ${index + 1} Non-preferred Image`}
                  />
                </ImageContainer>
              </>
            ) : (
              <>
                <ImageContainer isPreferred>
                  <Image
                    src={`data:image/jpeg;base64,${selection.image2.image_base64}`}
                    alt={`Selection ${index + 1} Preferred Image`}
                  />
                </ImageContainer>
                <ImageContainer isNonPreferred>
                  <Image
                    src={`data:image/jpeg;base64,${selection.image1.image_base64}`}
                    alt={`Selection ${index + 1} Non-preferred Image`}
                  />
                </ImageContainer>
              </>
            )}
          </SelectionContainer>
        ))}
      </RowContainer>
      <h4>Matches:</h4>
      <RowContainer>
        {matches.map((match, index) => (
          <ImageContainer key={index}>
            <Image
              src={`data:image/jpeg;base64,${match}`}
              alt={`Match ${index + 1}`}
            />
          </ImageContainer>
        ))}
      </RowContainer>
    </div>
  );
};

export default MatchesAndSelectionRow;
