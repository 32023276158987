import React, { FC } from "react";
import { ProspectProfile } from "../../types";

interface ProfileCardProps {
  profile: ProspectProfile; // Replace 'any' with a proper type for your profile object
  isSelected?: boolean;
  onToggleSelect?: () => void;
  onSelect?: (id: string) => void;
}

const formatHeight = (heightInInches: number): string => {
  const feet = Math.floor(heightInInches / 12);
  const inches = heightInInches % 12;
  return `${feet}'${inches}"`;
};

const renderProfileInfo = (info: any[], type: "profession" | "education") => {
  if (!info || info.length === 0) return null;

  return (
    <div className="text-sm ml-2 mt-1">
      <span className="font-semibold">
        {type === "profession" ? "Work" : "Education"}:
      </span>
      <ul className="list-disc list-inside">
        {info.slice(0, 2).map((item, index) => (
          <li key={index}>
            {type === "profession"
              ? `${item.position?.title} at ${item.company?.name}`
              : `${item.education?.degree} in ${item.education?.field} from ${item.school?.name}`}
          </li>
        ))}
      </ul>
    </div>
  );
};

const ProfileCard: FC<ProfileCardProps> = ({
  profile,
  isSelected,
  onToggleSelect,
  onSelect,
}) => {
  return (
    <div
      className={`flex flex-col items-center  border border-slate-500 rounded-md m-2 max-w-[250px] cursor-pointer ${
        isSelected ? "outline outline-4 outline-emerald-400" : ""
      }`}
      onClick={onToggleSelect}
    >
      <img
        src={`data:image/jpeg;base64,${profile.image_base64}`}
        alt="profile"
        key={profile.image_id}
        className="w-full rounded-t-md object-cover max-w-[250px]"
      />
      <p className="text-md font-semibold text-center">
        {profile.name} {" | "}
        {profile.age} {" | "}
        {formatHeight(profile.height)} {" | "}
        {profile.city}
      </p>
      {profile.religion && (
        <p className="text-sm text-center">{profile.religion.join(", ")}</p>
      )}
      {profile.interests && (
        <p className="text-sm text-center">{profile.interests.join(", ")}</p>
      )}
      {profile.key_attributes && (
        <div className="flex flex-row flex-wrap">
          {profile.key_attributes.map((attribute: string) => (
            <p
              key={attribute}
              className="text-sm text-center bg-indigo-500 p-1 rounded-md m-1 text-white"
            >
              {attribute}
            </p>
          ))}
        </div>
      )}
      {renderProfileInfo(profile.profession, "profession")}
      {renderProfileInfo(profile.education, "education")}
      <div className="mt-auto flex flex-row w-full">
        {onSelect && (
          <button
            className="bg-emerald-600 text-white p-2 rounded-md m-2 w-full"
            onClick={() => onSelect(profile._id)}
          >
            Match
          </button>
        )}
        <button
          className=" rounded-md m-2 w-full"
          onClick={() => navigator.clipboard.writeText(profile._id)}
        >
          Copy ID
        </button>
      </div>
    </div>
  );
};

export default ProfileCard;
