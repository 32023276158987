export default {
  BACKEND_API_ENDPOINT: process.env.REACT_APP_BACKEND_API_ENDPOINT,
  // BACKEND_API_ENDPOINT: "https://api.curate.date",
  STAGING_SITE_URL: "https://staging.curate.date",

  APPLICATION_LINK:
    process.env.REACT_APP_APPLICATION_LINK || "https://apply.curate.date",
  EVENTS_TO_FILTER_BY: [
    "event_request_for_verify_phone_number_sent",
    "event_recieved_verification_text",
    "event_sent_intro_text_message",
    "event_established_birectional_texting",
    "event_call_scheduled",
    "event_call_attempted",
    "event_call_completed",
    "event_fit_finder_sent",
    "event_fit_finder_started",
    "event_fit_finder_completed",
    "event_requested_another_match",
    "event_match_sent",
    "event_match_viewed",
    "event_match_pricing_viewed",
    "event_intro_pricing_viewed",
    "is_paying",
    "*",
  ],

  OPTIONAL_KEY_ATTRIBUTES: [
    "likes to travel",
    "athletic",
    "exceptional education",
    "exceptional career",
  ],
};
