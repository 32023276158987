import { useState, useEffect } from "react";
import { getFitFinderSelectionsAndMatches } from "../services/fitFinderService";

export const useFitFinder = (sessionId: string) => {
  const [selections, setSelections] = useState([]);
  const [matches, setMatches] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const data = await getFitFinderSelectionsAndMatches(sessionId);
        setSelections(data.selections);
        setMatches(data.matches);
      } catch (err) {
        setError(err instanceof Error ? err.message : String(err));
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [sessionId]);

  return { selections, matches, isLoading, error };
};
