import React, { FC } from "react";
import AppBar from "../../components/AppBar";

const Landing: FC = () => {
  return (
    <div className="flex flex-col items-center">
      <AppBar />

      <div className="p-12 space-y-2">
        <h2 className="text-4xl font-bold">Curate - Internal Tooling</h2>
        <ul>
          <li className="flex flex-col gap-2">
            <a href="/attractiveness" className="underline text-blue-800">
              Attractiveness
            </a>
            <a href="/fit-finder" className="underline text-blue-800">
              Fit Finder
            </a>
            <a href="/grid" className="underline text-blue-800">
              Grid
            </a>
            <a href="/crm" className="underline text-blue-800">
              CRM
            </a>
            <a href="/introductions" className="underline text-blue-800">
              Introductions
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Landing;
