import React from "react";
import logo from "./logo.svg";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Attractiveness from "./pages/attractiveness";
import Landing from "./pages/landing/indext";
import FitFinder from "./pages/fit-finder";
import Crm from "./pages/crm";
import UserDetailsPage from "./pages/crm/userPage";
import GridPage from "./pages/attractiveness/GridPage";
import FindMatches from "./pages/attractiveness/findMatches";
import Introductions from "./pages/introductions";
function App() {
  return (
    <Routes>
      <Route path="/" element={<Landing />} />
      <Route path="/attractiveness" element={<Attractiveness />} />
      <Route path="/fit-finder" element={<FitFinder />} />
      <Route path="/grid" element={<GridPage />} />
      <Route path="/crm" element={<Crm />} />
      <Route path="/crm/:id" element={<UserDetailsPage />} />
      <Route path="/matches/:id" element={<FindMatches />} />
      <Route path="/introductions" element={<Introductions />} />
    </Routes>
  );
}

export default App;
