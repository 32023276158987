import React, { FC, useState, useEffect, useCallback } from "react";
import { useAttractiveness } from "../../hooks/useAttractiveness";
import { useLocation } from "react-router-dom";

const Attractiveness: FC = () => {
  const location = useLocation();
  const [collectionName, setCollectionName] = useState("");
  const [isRating, setIsRating] = useState(false);
  const [pressedKey, setPressedKey] = useState<number | null>(null);
  const { currentImage, rateImage, isLoading, error } =
    useAttractiveness(collectionName);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const collectionNameFromUrl = params.get("collectionName");
    if (collectionNameFromUrl) {
      setCollectionName(collectionNameFromUrl);
    }
  }, [location]);

  const handleKeyPress = useCallback(
    (event: KeyboardEvent) => {
      const score = parseInt(event.key);
      if (score >= 1 && score <= 9) {
        setPressedKey(score);
        rateImage(score);
        setTimeout(() => setPressedKey(null), 500); // Display for 500ms
      }
    },
    [rateImage]
  );

  useEffect(() => {
    if (isRating) {
      window.addEventListener("keypress", handleKeyPress);
    }
    return () => {
      window.removeEventListener("keypress", handleKeyPress);
    };
  }, [isRating, handleKeyPress]);

  if (!isRating) {
    return (
      <div className="p-4">
        <h1 className="text-2xl font-bold mb-4">Attractiveness Rating</h1>
        <input
          type="text"
          value={collectionName}
          onChange={(e) => setCollectionName(e.target.value)}
          placeholder="Enter collection name"
          className="border p-2 mr-2"
        />
        <button
          onClick={() => setIsRating(true)}
          className="bg-blue-500 text-white px-4 py-2 rounded"
        >
          Start Rating
        </button>
      </div>
    );
  }

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-4">Attractiveness Rating</h1>
      {isLoading && <p>Loading...</p>}
      {error && <p className="text-red-500">{error}</p>}
      {currentImage && (
        <div>
          <img
            src={`data:image/jpeg;base64,${currentImage.image_base64}`}
            alt="Profile"
            className="max-w-md mb-4"
          />
          <p>Press a number key (1-9) to rate this image</p>
          {pressedKey && (
            <div className="text-4xl font-bold mt-4">Pressed: {pressedKey}</div>
          )}
        </div>
      )}
      {!currentImage && !isLoading && <p>No more images to rate</p>}
    </div>
  );
};

export default Attractiveness;
