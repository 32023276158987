import constants from "../constants";

export const getFitFinderSelectionsAndMatches = async (sessionId: string) => {
  try {
    const response = await fetch(
      `${constants.BACKEND_API_ENDPOINT}/fit_finder/fit_finder_selections_and_matches?session_id=${sessionId}`
    );

    if (!response.ok) {
      throw new Error("Failed to fetch fit finder selections and matches");
    }

    return await response.json();
  } catch (error) {
    console.error("Error fetching fit finder selections and matches:", error);
    throw error;
  }
};
