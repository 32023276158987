import constants from "../constants";

export const getProspectDetailsService = async (
  prospectId: string,
  accessToken: string
) => {
  const response = await fetch(
    `${constants.BACKEND_API_ENDPOINT}/internal/prospectContactDetails/${prospectId}`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  if (!response.ok) {
    throw new Error("Failed to fetch prospect details");
  }
  return response.json();
};

export const updateProspectDetailsService = async (
  prospectId: string,
  details: any,
  accessToken: string
) => {
  const response = await fetch(
    `${constants.BACKEND_API_ENDPOINT}/internal/updateProspectContactDetails/${prospectId}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(details),
    }
  );
  if (!response.ok) {
    throw new Error("Failed to update prospect details");
  }
  return response.json();
};
