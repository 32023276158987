import React from "react";

const ProspectSummary: React.FC<{ summary: string }> = ({ summary }) => {
  return (
    <div className="text-sm">
      {summary.split("; ").map((item: string) => (
        <div key={item} className="mb-2">
          <p>{item}</p>
        </div>
      ))}
    </div>
  );
};

export default ProspectSummary;
