import { useAuth0 } from "@auth0/auth0-react";

const AppBar = () => {
  const { user, isAuthenticated, loginWithRedirect, logout } = useAuth0();

  return (
    <div className="flex justify-between items-center p-4 w-full bg-amber-100">
      <div className="flex items-center">
        <span className="ml-2">{user?.name}</span>
      </div>
      <div>
        {!isAuthenticated ? (
          <button
            onClick={() => loginWithRedirect()}
            className="underline py-2 px-2 rounded text-blue-800"
          >
            login
          </button>
        ) : (
          <button
            onClick={() => logout()}
            className="underline py-2 px-2 rounded text-blue-800"
          >
            logout
          </button>
        )}
      </div>
    </div>
  );
};

export default AppBar;
